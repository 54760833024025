import React from 'react'
import Helmet from 'react-helmet';
import Layout from '../components/layout'
import Header from '../components/header'
import '../components/blog.css'

const Advertise = () => (
    <Layout>
        <Helmet title="Advertise" />
        <Header siteTitle={'Reactgo'} />
        <div className="disclosure" >
            <div>
                <h1 style={{ color: "rgb(85, 84, 84)" }}>Advertise on Reactgo</h1>
                <p>
                    Partner with reactgo.com and get your brand or product visibility to the thousands
                    of Software developers and programmers.
                </p>
                <ul>
                    <li>Total number of tutorials published 1000+</li>
                    <li>Daily page views: 10000+</li>
                    <li>Highly engaged audience</li>
                </ul>
                <hr />
                <h2 style={{ color: "rgb(85, 84, 84)" }}>Premium Sponsorship (899$)</h2>
                <p>Premium Sponsorship helps you to reach a wider audience because
                    we feature your product in between every tutorial on the site.</p>
                <ul>
                    <li>Exclusive - Limited to one spot per month.</li>
                    <li>Your product is featured in between every tutorial.</li>
                    <li>High reach. In front of new users every day.</li>
                    <li> 2m+ impressions every month</li>
                </ul>
                Example:

                <img src="https://i.imgur.com/nhsi0L2.png" style={{ border: "2px dashed red" }} alt="reactgo.com advertise"></img>
                <p style={{ padding: "1rem" }}>
                    If you are interested in sponsoring email us at - <i>saigowthamrr@gmail.com</i>
                </p>
                <hr />


                <h2 style={{ color: "rgb(85, 84, 84)" }}>Sponsored Content (499$)</h2>
                <p>
                    Sponsored content is best way to get your product infront of many developers.
                </p>
                <ul style={{ paddingBottom: "2rem" }}>
                    <li>Exclusive - Limited to one spot per week.</li>
                    <li>A dedicated sponsored article about your company or product.</li>
                    <li>Your product is featured on the Home page for an entire week.</li>
                </ul>

            </div>
        </div>
    </Layout>
)

export default Advertise;